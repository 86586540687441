import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./bottomBanner.module.css";
import { selectAuth } from "../../../../features/auth/selectors";

export default function BottomBanner() {
  const { t } = useTranslation();
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const { isAuth } = auth;

  const handleClick = () => {
    if (isAuth) {
      navigate("/account/mySynthetic");
    } else {
      navigate("/signUp");
    }
  };

  return (
    <div className={styles.bannerBlock}>
      <img className={styles.topImg} src="/topImg-banner.webp" alt="top-img" />
      <div className={styles.contextBox}>
        <p className={styles.text}>{t("getStarted")}</p>
        <div className={styles.buttonOpenAccount}>
          <button type="button" className={styles.parentComponent} onClick={handleClick}>
            <span>{t("openAccount")}</span>
            <img src="/arrow-right-white.webp" alt="arrow-right" />
          </button>
        </div>
      </div>
    </div>
  );
}
