import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import styles from "./flagPage.module.css";
import { setOnVideo } from "../../synthetic/syntheticParamsSlice";

function FlagPage() {
  const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currLanguage = useSelector((state) => state.language.language);
  const flags = useMemo(
    () => [
      { lang: "en", imagePath: "/web_UK.webp", label: "England" },
      { lang: "es", imagePath: "/web_ES.webp", label: "Spain" },
      { lang: "fi", imagePath: "/web_FI.webp", label: "Finland" },
      { lang: "fr", imagePath: "/web_FR.webp", label: "France" },
      { lang: "de", imagePath: "/web_DE.webp", label: "Germany" },
    ],
    []
  );

  const sortedFlags = useMemo(() => {
    const selectedIndex = flags.findIndex((flag) => flag.lang === currLanguage);
    const selectedFlag = flags[selectedIndex];
    const newFlags = [...flags];
    newFlags.splice(selectedIndex, 1);
    newFlags.splice(2, 0, selectedFlag);

    return newFlags;
  }, [currLanguage, flags]);

  const handleFlagClick = (lang) => {
    let newOnVideo;
    switch (lang) {
      case "en":
        newOnVideo = {
          videoPath: `${videoUrl}/SynFlagsEn.mp4`,
          question: false,
        };
        break;
      case "de":
        newOnVideo = {
          videoPath: `${videoUrl}/SynFlagsDe.mp4`,
          question: false,
        };
        break;
      case "fi":
        newOnVideo = {
          videoPath: `${videoUrl}/SynFlagsFi.mp4`,
          question: false,
        };
        break;
      case "fr":
        newOnVideo = {
          videoPath: `${videoUrl}/SynFlagsFr.mp4`,
          question: false,
        };
        break;
      case "es":
        newOnVideo = {
          videoPath: `${videoUrl}/SynFlagsEs.mp4`,
          question: false,
        };
        break;
      case "fun":
        newOnVideo = {
          videoPath: `${videoUrl}/ChineseFun.mp4`,
          question: false,
        };
        break;
      default:
        newOnVideo = {
          videoPath: `${videoUrl}/SynFlagsEn.mp4`,
          question: false,
        };
        break;
    }
    dispatch(setOnVideo(newOnVideo));
  };

  useEffect(() => {
    handleFlagClick("fun");
  });

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
        <div className={styles.languageContainer}>
          <div className={styles.imageContainer}>
            {sortedFlags.length > 0 &&
              sortedFlags.map((flag) => (
                <button
                  key={flag.lang}
                  type="button"
                  className={styles.flagButton}
                  onClick={() => handleFlagClick(flag.lang)}
                  aria-label={flag.label}
                >
                  <img className={styles.imgBtn} src={flag.imagePath} alt={flag.label} />
                </button>
              ))}
            <button
              type="button"
              className={styles.handButton}
              style={{ backgroundImage: `url(/hand.webp)` }}
              aria-label="click"
            />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.languageHeading}>
              <p className={styles.heading}>{t("languageHeading")}</p>
            </div>
            <div className={styles.languageSubtitle}>
              <p className={styles.subtitle}>{t("languageSubtitle")}</p>
            </div>
          </div>
          <div className={styles.flagsContainer}>
            <div className={styles.gradientLeft} />
            <div className={styles.flagsScroll}>
              <div className={`${styles.flag} ${styles.flagBR}`} />
              <div className={`${styles.flag} ${styles.flagCN}`} />
              <div className={`${styles.flag} ${styles.flagCZ}`} />
              <div className={`${styles.flag} ${styles.flagDK}`} />
              <div className={`${styles.flag} ${styles.flagDE}`} />
              <div className={`${styles.flag} ${styles.flagES}`} />
              <div className={`${styles.flag} ${styles.flagFI}`} />
              <div className={`${styles.flag} ${styles.flagFR}`} />
              <div className={`${styles.flag} ${styles.flagLU}`} />
              <div className={`${styles.flag} ${styles.flagGB}`} />
              <div className={`${styles.flag} ${styles.flagGE}`} />
              <div className={`${styles.flag} ${styles.flagGR}`} />
              <div className={`${styles.flag} ${styles.flagHR}`} />
              <div className={`${styles.flag} ${styles.flagIN}`} />
              <div className={`${styles.flag} ${styles.flagAT}`} />
              <div className={`${styles.flag} ${styles.flagBE}`} />
              <div className={`${styles.flag} ${styles.flagBG}`} />
              <div className={`${styles.flag} ${styles.flagIS}`} />
              <div className={`${styles.flag} ${styles.flagHU}`} />
              <div className={`${styles.flag} ${styles.flagLI}`} />
              <div className={`${styles.flag} ${styles.flagNL}`} />
              <div className={`${styles.flag} ${styles.flagNO}`} />
              <div className={`${styles.flag} ${styles.flagBR}`} />
              <div className={`${styles.flag} ${styles.flagCN}`} />
              <div className={`${styles.flag} ${styles.flagCZ}`} />
              <div className={`${styles.flag} ${styles.flagDK}`} />
              <div className={`${styles.flag} ${styles.flagDE}`} />
              <div className={`${styles.flag} ${styles.flagES}`} />
              <div className={`${styles.flag} ${styles.flagFI}`} />
              <div className={`${styles.flag} ${styles.flagFR}`} />
              <div className={`${styles.flag} ${styles.flagLU}`} />
              <div className={`${styles.flag} ${styles.flagGB}`} />
              <div className={`${styles.flag} ${styles.flagGE}`} />
              <div className={`${styles.flag} ${styles.flagGR}`} />
              <div className={`${styles.flag} ${styles.flagHR}`} />
              <div className={`${styles.flag} ${styles.flagIN}`} />
              <div className={`${styles.flag} ${styles.flagAT}`} />
              <div className={`${styles.flag} ${styles.flagBE}`} />
              <div className={`${styles.flag} ${styles.flagBG}`} />
              <div className={`${styles.flag} ${styles.flagIS}`} />
              <div className={`${styles.flag} ${styles.flagHU}`} />
              <div className={`${styles.flag} ${styles.flagLI}`} />
              <div className={`${styles.flag} ${styles.flagNL}`} />
              <div className={`${styles.flag} ${styles.flagNO}`} />
            </div>
            <div className={styles.gradientRight} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlagPage;
