import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./introBlock.module.css";
import { selectAuth } from "../../../../features/auth/selectors";

function IntroBlock() {
  const { t } = useTranslation();
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const { isAuth } = auth;

  const handleAccountClick = () => {
    if (isAuth) {
      navigate("/account/mySynthetic");
    } else {
      navigate("/signUp");
    }
  };

  const handleCustomerClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
        <div className={styles.heroIllustration} />
        <div className={styles.introContainer}>
          <div className={styles.textContainer}>
            <div className={styles.introHeading}>
              <p className={styles.heading}>{t("introHeading")}</p>
            </div>
            <div className={styles.introSubtitle}>
              <p className={styles.subtitle}>{t("introSubtitle")}</p>
            </div>
          </div>

          <div className={styles.buttonOpenAccount}>
            <button type="button" className={styles.parentComponent} onClick={handleAccountClick}>
              <span>{t("openAccount")}</span>
              <img src="/arrow-right-white.webp" alt="arrow-right" />
            </button>
          </div>
        </div>
        <div className={styles.customersSection}>
          <h2 className={styles.customersTitle}>Our Customers</h2>
          <div className={styles.customersButtons}>
            <button className={styles.customerButton} onClick={() => handleCustomerClick("https://aquavilla.org/")}>
              <img src="/converted_image_1.webp" alt="Customer 1" />
            </button>
            <button className={styles.customerButton} onClick={() => handleCustomerClick("https://revx.nl/")}>
              <img src="/converted_image_2.webp" alt="Customer 2" />
            </button>
            <button className={styles.customerButton} onClick={() => handleCustomerClick("https://solinvestor.com/")}>
              <img src="/converted_image_3.webp" alt="Customer 3" />
            </button>
            <button className={styles.customerButton} onClick={() => handleCustomerClick("https://www.digiapusi.fi/")}>
              <img src="/converted_image_4.webp" alt="Customer 4" />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.introducingContainer}>
        <div className={styles.introducing}>
          <p className={styles.introducingText}>{t("introducing")}</p>
        </div>
      </div>
    </div>
  );
}

export default IntroBlock;
