import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import styles from "./terms.module.css";
import i18n from "../../locales/i18n";

export default function Terms() {
  const { t } = useTranslation();
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      document.documentElement.lang = lng;
    };
    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className={styles.pageBox}>
      <div className={styles.contextPageBox}>
        <h1 className={styles.titlePage}>{t("termsAndConditionsTitle")}</h1>
        <p className={styles.updateInfo}>{t("lastUpdated")} 01.03.2024</p>
        <p className={styles.text}>{t("termsOfService")}</p>
        <h3 className={styles.titleParagraph}>1. {t("serviceAndPlans")}</h3>
        <p className={styles.itemName}>
          <b>1.1 </b>
          {t("servicePlans")}
        </p>
        <p className={styles.text}>{t("servicePlansOffer")}</p>
        <p className={styles.text}>{t("agreementTerms")}</p>
        <p className={styles.text}>{t("upgradeDowngradePolicy")}</p>
        <p className={styles.itemName}>
          <b>1.2 </b>
          {t("installingSiltacom")}
        </p>
        <p className={styles.text}>{t("submittingURL")}</p>
        <p className={styles.text}>{t("serviceActivation")}</p>
        <p className={styles.text}>{t("intellectualPropertyRights")}</p>
        <p className={styles.itemName}>
          <b>1.3 </b>
          {t("prohibitedUses")}
        </p>
        <p className={styles.text}>{t("prohibitedActions")}</p>
        <p className={styles.itemName}>
          <b>1.4 </b>
          {t("serviceChanges")}
        </p>
        <p className={styles.text}>{t("serviceChangesPolicy")}</p>
        <h3 className={styles.titleParagraph}>2. {t("materials")}</h3>
        <p className={styles.text}>{t("materialsResponsibility")}</p>
        <p className={styles.text}>{t("siltacomRole")}</p>
        <h3 className={styles.titleParagraph}>3. {t("paymentTerms")}</h3>
        <p className={styles.text}>{t("paymentDetails")}</p>
        <p className={styles.text}>{t("billingFrequency")}</p>
        <p className={styles.text}>{t("paymentAuthorization")}</p>
        <p className={styles.text}>{t("outstandingBalance")}</p>
        <p className={styles.text}>{t("latePaymentInterest")}</p>
        <p className={styles.text}>{t("taxLiability")}</p>
        <h3 className={styles.titleParagraph}>4. {t("intellectualPropertyRightsTitle")}</h3>
        <p className={styles.itemName}>
          <b>4.1 </b>
          {t("theService")}
        </p>
        <p className={styles.text}>{t("ownershipAndUse")}</p>
        <p className={styles.itemName}>
          <b>4.2 </b>
          {t("website")}
        </p>
        <p className={styles.text}>{t("websiteOwnership")}</p>
        <p className={styles.itemName}>
          <b>4.3 </b>
          {t("thirdPartyIPRights")}
        </p>
        <p className={styles.text}>{t("reportIPViolation")}</p>
        <p className={styles.text}>{t("noticeInformation")}</p>
        <h3 className={styles.titleParagraph}>5. {t("indemnification")}</h3>
        <p className={styles.text}>{t("indemnificationAgreement")}</p>
        <h3 className={styles.titleParagraph}>6. {t("noWarrantyLimitation")}</h3>
        <p className={styles.text}>{t("noWarrantyDisclaimer")}</p>
        <p className={styles.text}>{t("serviceSuspension")}</p>
        <p className={styles.text}>{t("liabilityClause")}</p>
        <h3 className={styles.titleParagraph}>7. {t("termination")}</h3>
        <p className={styles.text}>{t("terminationClause")}</p>
        <p className={styles.text}>{t("refundPolicy")}</p>
        <p className={styles.text}>{t("terminationActions")}</p>
        <h3 className={styles.titleParagraph}>8. {t("otherProvisions")}</h3>
        <p className={styles.itemName}>
          <b>8.1 </b>
          {t("confidentiality")}
        </p>
        <p className={styles.text}>{t("confidentialityClause")}</p>
        <p className={styles.itemName}>
          <b>8.2 </b>
          {t("reference")}
        </p>
        <p className={styles.text}>{t("marketingUsage")}</p>
        <p className={styles.itemName}>
          <b>8.3 </b>
          {t("amendments")}
        </p>
        <p className={styles.text}>{t("amendmentPolicy")}</p>
        <p className={styles.itemName}>
          <b>8.4 </b>
          {t("entireAgreement")}
        </p>
        <p className={styles.text}>{t("entireAgreementClause")}</p>
        <p className={styles.itemName}>
          <b>8.5 </b>
          {t("independentContractors")}
        </p>
        <p className={styles.text}>{t("independentContractorClause")}</p>
        <p className={styles.itemName}>
          <b>8.6 </b>
          {t("noWaiver")}
        </p>
        <p className={styles.text}>{t("noWaiverClause")}</p>
        <p className={styles.itemName}>
          <b>8.7 </b>
          {t("severability")}
        </p>
        <p className={styles.text}>{t("severabilityClause")}</p>
        <p className={styles.itemName}>
          <b>8.8 </b>
          {t("assignment")}
        </p>
        <p className={styles.text}>{t("assignmentClause")}</p>
        <p className={styles.itemName}>
          <b>8.9 </b>
          {t("governingLaw")}
        </p>
        <p className={styles.text}>{t("governingLawClause")}</p>
      </div>
    </div>
  );
}
