import { useTranslation } from "react-i18next";
import { useState } from "react";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import styles from "./blogPage.module.css";
import Terms from "../terms/Terms";
import Privacy from "../privacy/Privacy";
import { addNewsletter } from "./api";

const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";
const newsItems = [
  {
    date: "05.03.2024",
    contentType: "text",
    textKey: "News Item 1",
    title: "Title 1",
    summary: "summary1",
    imageUrl: "/ai_man.webp",
    fullTextP1: "fullTextNews1P1",
    fullTextP2: "fullTextNews1P2",
    fullTextP3: "fullTextNews1P3",
  },
  {
    date: "05.03.2024",
    contentType: "video",
    textKey: "Video1",
    title: "TitleVideo1",
    summary: "summaryVideo1",
    videoUrl: `${videoUrl}/SynIntroEn.mp4`,
    fullTextP1: "fullTextVideo1P1",
    fullTextP2: "fullTextVideo1P2",
    fullTextP3: "fullTextVideo1P3",
    fullTextP4: "fullTextVideo1P4",
    fullTextP5: "fullTextVideo1P5",
  },
  {
    date: "04.03.2024",
    contentType: "video",
    textKey: "Video2",
    title: "TitleVideo2",
    summary: "summaryVideo2",
    videoUrl: "work.mp4",
    fullTextP1: "fullTextVideo2P1",
    fullTextP2: "fullTextVideo2P2",
    fullTextP3: "fullTextVideo2P3",
    fullTextP4: "fullTextVideo2P4",
  },

  {
    date: "03.03.2024",
    contentType: "text",
    textKey: "News Item 2",
    title: "Title 2",
    summary: "summary2",
    imageUrl: "/synthetic.webp",
    fullTextP1: "fullTextNews2P1",
    fullTextP2: "fullTextNews2P2",
    fullTextP3: "fullTextNews2P3",
  },
];

export default function BlogPage() {
  const { t, i18n } = useTranslation();
  const [activeVideo, setActiveVideo] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentKey, setModalContentKey] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleOpenModal = (contentKey) => {
    setModalContentKey(contentKey);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [expandedItem, setExpandedItem] = useState(null);

  const toggleExpandItem = (index) => {
    setExpandedItem((prevIndex) => (prevIndex === index ? null : index));
  };

  const checkFullCorrect = () => {
    return email && !emailError;
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.trim();
    const isValidEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(emailValue);
    setEmail(emailValue);
    setEmailError(!isValidEmail);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);

    const newsletter = {
      email,
    };

    const res = await addNewsletter(newsletter);
    if (res.email === email) {
      setIsSending(false);
      setIsSubmittedSuccessfully(true);
    } else {
      setIsSending(false);
      setIsSubmittedSuccessfully(false);
    }
  };

  const stopOtherVideos = () => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      const videoSrc = video.src;
      if (videoSrc && !videoSrc.endsWith(activeVideo)) {
        video.pause();
      }
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.blogsContainer}>
        <h1 className={styles.title}>{t("blogTitle")}</h1>
        {isModalOpen && (
          <div className={styles.modalOverlay} onClick={handleCloseModal} role="button" tabIndex="0">
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()} role="button" tabIndex="0">
              <button type="button" className={styles.closeButton} onClick={handleCloseModal}>
                ×
              </button>
              <div className={styles.modalBody}>
                {modalContentKey === "terms" && <Terms />}
                {modalContentKey === "privacy" && <Privacy />}
              </div>
            </div>
          </div>
        )}
        <div className={styles.subscriptionForm}>
          {!isSubmittedSuccessfully && !isSending ? (
            <>
              <p className={styles.subscriptionText}> {t("subscribe")}</p>
              <form onSubmit={handleSubmit}>
                <label htmlFor="emailInput" className={styles.visuallyHidden}>
                  Email Address
                </label>
                <div className={styles.inputContainer}>
                  <input
                    id="emailInput"
                    type="email"
                    placeholder={t("emailExample")}
                    value={email}
                    onChange={handleEmailChange}
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                    title={t("emailRequirements")}
                    className={styles.emailInput}
                  />
                  <button
                    type={checkFullCorrect() ? "submit" : "button"}
                    className={`${styles.subscribeButton} ${checkFullCorrect() ? "" : styles.noActive}`}
                  >
                    {t("subscribeBtn")}
                  </button>
                </div>
              </form>
              <div className={styles.agreementTextContainer}>
                <div className={styles.agreementText}>
                  {t("agreementText1")}
                  <button type="button" onClick={() => handleOpenModal("terms")} className={styles.link} id="termsLink">
                    {t("terms-and-conditions")}
                  </button>
                  {t("agreementText2")}
                  <button
                    type="button"
                    onClick={() => handleOpenModal("privacy")}
                    className={styles.link}
                    id="privacyPolicyLink"
                  >
                    {t("privacy-policy")}
                  </button>
                  {i18n.language === "de" && t("agreementText3")}
                </div>
              </div>
            </>
          ) : (
            <div className={styles.confirmBox}>
              {isSending ? (
                <p className={styles.sendingMessage}>{t("sendingMessage")}</p>
              ) : (
                isSubmittedSuccessfully && (
                  <>
                    <h3 className={styles.titlePage}>{t("Successful")}</h3>

                    <p className={styles.successfulFormText}>
                      <span>{t("thankYouMessage")}</span>
                      <ThumbUpAltIcon className={styles.iconSubscribing} />
                    </p>
                    <p className={styles.successfulFormText}>{t("subscriptionConfirmation")}</p>
                    <p className={styles.successfulFormText}>{t("keepFollowingUpdates")}</p>
                  </>
                )
              )}
            </div>
          )}
        </div>

        <div className={styles.newsBlog}>
          {newsItems.map((item, index) => (
            <div key={item.textKey} className={styles.blog}>
              <div className={styles.blogContainer} style={{ backgroundColor: item.color }}>
                <div className={styles.blogDatum}>
                  <p className={styles.datum} style={{ color: item.textColor }}>
                    {item.date}
                  </p>
                </div>

                <div className={styles.secondaryBlock}>
                  {item.contentType === "text" ? (
                    <div className={styles.mediaContainer} style={{ backgroundImage: `url(${item.imageUrl})` }} />
                  ) : (
                    <div className={styles.mediaContainer}>
                      <video
                        src={item.videoUrl}
                        // type="video/webm"
                        className={styles.newsVideo}
                        controls
                        autoPlay={false}
                        onPlay={() => {
                          setActiveVideo(item.videoUrl);
                          stopOtherVideos();
                        }}
                      >
                        <track kind="captions" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                  <div className={styles.newsContent}>
                    <h3 className={styles.newsTitle}>{t(item.title)}</h3>
                    {!expandedItem !== null && expandedItem !== index && (
                      <p className={`${styles.newsSummary}`}>{t(item.summary)}</p>
                    )}
                    {expandedItem === index && (
                      <>
                        <p className={styles.newsSummary}>{t(item.fullTextP1)}</p>
                        <p className={styles.newsSummary}>{t(item.fullTextP2)}</p>
                        <p className={styles.newsSummary}>{t(item.fullTextP3)}</p>
                        <p className={styles.newsSummary}>{t(item.fullTextP4)}</p>
                        <p className={styles.newsSummary}>{t(item.fullTextP5)}</p>
                      </>
                    )}
                    <button type="button" onClick={() => toggleExpandItem(index)} className={styles.moreButton}>
                      {expandedItem === index ? t("Less") : t("More")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
