import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPopupVisible: false,
  videoFile: undefined,
};

const syntheticSlice = createSlice({
  name: "synthetic",
  initialState,
  reducers: {
    showPopup: (state) => {
      state.isPopupVisible = true;
    },
    hidePopup: (state) => {
      state.isPopupVisible = false;
    },
    setVideoFile: (state, action) => {
      state.videoFile = action.payload;
    },
  },
});

export const { showPopup, hidePopup, setVideoFile } = syntheticSlice.actions;
export default syntheticSlice.reducer;
