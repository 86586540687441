import Cookies from "js-cookie";
import { axiosDefault, axiosWithAuth } from "./service/axios.config";

export async function register(data) {
  const res = await axiosDefault.post("/api/users/register", JSON.stringify(data));
  if (res.status !== 201 && res.status !== 409) {
    throw res.data;
  }
  return res.data;
}

export async function update(data) {
  const res = await axiosWithAuth.put(`/api/users/${data?.id}`, JSON.stringify(data));
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}

export async function recoveryEmail(data) {
  const res = await axiosDefault.get(`/api/users/recoveryEmail/${data.email}`);
  if (res.status !== 200 && res.status !== 409) {
    throw res.data;
  }
  return res.data;
}

export async function changePassword(data) {
  const res = await axiosDefault.post(`/api/users/changePassword`, JSON.stringify(data));
  if (res.status !== 201 && res.status !== 409) {
    throw res.data;
  }
  return res.data;
}

export async function confirmEmail(confirmBody) {
  const res = await axiosDefault.post(`/api/users/confirm/${confirmBody.code}`, JSON.stringify(confirmBody));
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}

export async function login(data) {
  const res = await axiosDefault.post("/api/auth/login", JSON.stringify(data));
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}

export async function newAccessToken() {
  const res = await axiosDefault.post("/api/auth/token", JSON.stringify({ refreshToken: Cookies.get("refreshToken") }));
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}

export async function getAuthUser() {
  const res = await axiosWithAuth.get("/api/users/userByAuth");
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}
