import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Subscription from "../../features/subscription/Subscription";
import styles from "./pricePage.module.css";
import { getPricing, getPricingConfig } from "../price_request/priceSlice";
import { selectLoading } from "../../features/subscription/selectors";
import { getUserActiveSubscriptionDetails } from "../../features/subscription/subscriptionSlice";
import PreLoading from "../pre_loading/PreLoading";
import { selectIsAuth } from "../../features/auth/selectors";

export default function PricePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [minFree, setMinFree] = useState(0);
  const [minBasic, setMinBasic] = useState(0);
  const [minPro, setMinPro] = useState(0);
  const [priceFree, setPriceFree] = useState("0");
  const [priceBasic, setPriceBasic] = useState("0");
  const [pricePro, setPricePro] = useState("0");
  const [pagesFree, setPagesFree] = useState(0);
  const [pagesBasic, setPagesBasic] = useState(0);
  const [pagesPro, setPagesPro] = useState(0);
  const [discountBasic, setDiscountBasic] = useState(0);
  const [discountPro, setDiscountPro] = useState(0);
  const [domainFree, setDomainFree] = useState(0);
  const [domainBasic, setDomainBasic] = useState(0);
  const [domainPro, setDomainPro] = useState(0);
  const [isMonthOrYear, setIsMonthOrYear] = useState("year");
  const [voicesFree, setVoicesFree] = useState("Various");
  const [voicesBasic, setVoicesBasic] = useState("Various");
  const [voicesPro, setVoicesPro] = useState("Various");
  const [langFree, setLangFree] = useState(22);
  const [langBasic, setLangBasic] = useState(22);
  const [langPro, setLangPro] = useState(22);
  const [videoFree, setVideoFree] = useState("");
  const [videoBasic, setVideoBasic] = useState("€5");
  const [videoPro, setVideoPro] = useState("€3.5");
  const [avatarFree, setAvatarFree] = useState(0);
  const [avatarBasic, setAvatarBasic] = useState(0);
  const [avatarPro, setAvatarPro] = useState(0);

  const monthlyPriceWithDiscount = (monthlyPrice, discount) => {
    const monthly = parseFloat(monthlyPrice.replace("€", ""));
    const discountedMonthly = Math.round(monthly * (1 - discount / 100));
    return `€${discountedMonthly}`;
  };

  const getData = async function getData() {
    const pricing = await dispatch(getPricing());
    setDiscountBasic(String(pricing.payload[1].discount));
    setDiscountPro(String(pricing.payload[2].discount));
    setPriceFree(`€${String(pricing.payload[0].monthlyPrice)}`);
    setPriceBasic(`€${String(pricing.payload[1].monthlyPrice)}`);
    setPricePro(`€${String(pricing.payload[2].monthlyPrice)}`);
    const pricingConf = await dispatch(getPricingConfig());
    setPagesFree(pricingConf.payload[0].pagesPerDomain);
    setPagesBasic(pricingConf.payload[1].pagesPerDomain);
    setPagesPro(pricingConf.payload[2].pagesPerDomain);
    setDomainFree(pricingConf.payload[0].domainsToConnect);
    setDomainBasic(pricingConf.payload[1].domainsToConnect);
    setDomainPro(pricingConf.payload[2].domainsToConnect);
    setMinFree((pricingConf.payload[0].coinsLimitPerMonth * 15) / 60);
    setMinBasic((pricingConf.payload[1].coinsLimitPerMonth * 15) / 60);
    setMinPro((pricingConf.payload[2].coinsLimitPerMonth * 15) / 60);
    setVoicesFree(pricingConf.payload[0].variousVoices ? "Various" : "One");
    setVoicesBasic(pricingConf.payload[1].variousVoices ? "Various" : "One");
    setVoicesPro(pricingConf.payload[2].variousVoices ? "Various" : "One");
    setAvatarFree(pricingConf.payload[0].avatarsIncluded);
    setAvatarBasic(pricingConf.payload[1].avatarsIncluded);
    setAvatarPro(pricingConf.payload[2].avatarsIncluded);
  };

  const isAuth = useSelector(selectIsAuth);

  useEffect(() => {
    getData();
  }, [isMonthOrYear]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserActiveSubscriptionDetails());
    }
  }, [dispatch, isAuth]);

  const loading = useSelector(selectLoading);

  if (loading) {
    return <PreLoading />;
  }

  const isMonthlyPrice = isMonthOrYear === "month";

  return (
    <div className={styles.pricingBox}>
      <h1 className={styles.title}>{t("pricingTitle")}</h1>
      <div className={styles.buttonBox}>
        <button
          className={`${styles.monthButton} ${isMonthOrYear === "month" ? styles.activeBtn : ""}`}
          type="button"
          onClick={() => setIsMonthOrYear("month")}
          id="monthlyPricingButton"
        >
          {t("monthly")}
        </button>
        <button
          className={`${styles.yearButton} ${isMonthOrYear === "year" ? styles.activeBtn : ""}`}
          type="button"
          onClick={() => setIsMonthOrYear("year")}
          id="yearlyPricingButton"
        >
          {t("yearly")}
        </button>
      </div>
      <div className={styles.contextBox}>
        <div className={styles.freeCard}>
          <div className={styles.freeCardContext}>
            <div className={styles.textBox}>
              <div className={`${styles.priceCardBox} ${isMonthOrYear === "year" ? styles.overlap : ""}`}>
                {isMonthOrYear === "year" && (
                  <>
                    <div className={styles.giftBox}>
                      <p className={styles.titleCard}>{t("Starter")}</p>
                    </div>
                    <span className={styles.newAnnualPrice}>{monthlyPriceWithDiscount(priceFree, 0)}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                    <div className={styles.monthlyPriceText}>{t("discountedMonthlyPriceFree")}</div>
                  </>
                )}
                {isMonthOrYear === "month" && (
                  <>
                    <div className={styles.giftBox}>
                      <p className={styles.titleCard}>{t("Starter")}</p>
                    </div>
                    <span className={styles.price}>{priceFree}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                  </>
                )}
              </div>
              <p className={styles.sepLine} />
              <p className={styles.descriptionCard}>
                <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
                <span>
                  {domainFree} {t("domainsToConnect")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
                <span>
                  {pagesFree} {t("pagesForOneDomain")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
                <span>
                  {minFree} {t("generationTime")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-rot.webp" alt="circle-check" width="25" height="25" />
                <span>
                  {videoFree} {t("extraVideoFree")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
                <span>
                  {avatarFree} {t("avatar")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
                <span>
                  {voicesFree} {t("voices")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
                <span>
                  {langFree} {t("language")}
                </span>
              </p>
              <div style={{ height: "49px" }} />
            </div>
          </div>
        </div>

        <div className={styles.basicCard}>
          <div className={styles.basicCardContext}>
            <div className={styles.textBox}>
              <div className={`${styles.priceCardBox} ${isMonthOrYear === "year" ? styles.overlap : ""}`}>
                {isMonthOrYear === "year" && (
                  <>
                    <div className={styles.giftBox}>
                      <p className={styles.titleCard}>{t("basic")}</p>
                    </div>
                    <span className={styles.newAnnualPrice}>{monthlyPriceWithDiscount(priceBasic, discountBasic)}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                    <span className={styles.asterisk}> *</span>
                    <div className={styles.asteriskBlock}>
                      <span className={styles.asterisk}>* </span>
                      <div className={styles.monthlyPriceText}>{t("discountedMonthlyPrice")}</div>
                    </div>
                  </>
                )}
                {isMonthOrYear === "month" && (
                  <>
                    <div className={styles.giftBox}>
                      <p className={styles.titleCard}>{t("basic")}</p>
                    </div>
                    <span className={styles.price}>{priceBasic}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                  </>
                )}
              </div>
              <p className={styles.sepLine} />
              <p className={styles.descriptionCard}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {domainBasic} {t("domainsToConnect")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {pagesBasic} {t("pagesForOneDomain")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {minBasic} {t("generationTime")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {videoBasic} {t("extraVideo")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {avatarBasic} {t("avatar")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {voicesBasic} {t("voices")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {langBasic} {t("language")}
                </span>
              </p>
              <Subscription isProTariff={false} isRecurringPrice={!isMonthlyPrice} />
            </div>
          </div>
        </div>

        <div className={styles.proCard}>
          <div className={styles.proCardContext}>
            <div className={styles.textBox}>
              <div className={`${styles.priceCardBox} ${isMonthOrYear === "year" ? styles.overlap : ""}`}>
                {isMonthOrYear === "year" && (
                  <>
                    <div className={styles.giftBox}>
                      <p className={styles.titleCard}>Pro</p>
                    </div>
                    <span className={styles.newAnnualPrice}>{monthlyPriceWithDiscount(pricePro, discountPro)}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                    <span className={styles.asterisk}> *</span>
                    <div className={styles.asteriskBlock}>
                      <span className={styles.asterisk}>* </span>
                      <div className={styles.monthlyPriceText}>{t("discountedMonthlyPrice")}</div>
                    </div>
                  </>
                )}

                {isMonthOrYear === "month" && (
                  <>
                    <div className={styles.giftBox}>
                      <p className={styles.titleCard}>Pro</p>
                    </div>
                    <span className={styles.price}>{pricePro}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                  </>
                )}
              </div>
              <p className={styles.sepLine} />
              <p className={styles.descriptionCard}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {domainPro} {t("domainsToConnect")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {pagesPro} {t("pagesForOneDomain")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {minPro} {t("generationTime")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {videoPro} {t("extraVideo")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {avatarPro} {t("avatar")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {voicesPro} {t("voices")}
                </span>
              </p>
              <p className={styles.descriptionCard}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {langPro} {t("language")}
                </span>
              </p>
              <Subscription isProTariff isRecurringPrice={!isMonthlyPrice} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
