import IntroBlock from "./blocks/intro/IntroBlock";
import TechBlock from "./blocks/tech/TechBlock";
import BottomBanner from "./blocks/banner/BottomBanner";
import Pricing from "./blocks/price/Pricing";
import FAQBlock from "./blocks/faq/FAQBlock";

function Home() {
  return (
    <>
      <IntroBlock />
      <TechBlock />
      <Pricing />
      <FAQBlock />
      <BottomBanner />
    </>
  );
}

export default Home;
