import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "./api";

const initialState = {
  hasSubscribe: false,
  subscription: {
    id: undefined,
    status: undefined,
    priceType: undefined,
    tariff: undefined,
    currency: undefined,
    amount: undefined,
    endDate: undefined,
    startDate: undefined,
  },
  isLoading: false,
  error: false,
};

export const subscribe = createAsyncThunk("subscribe", async (data) => api.subscribe(data));
export const getUserActiveSubscriptionDetails = createAsyncThunk("getUserActiveSubscriptionDetails", async () =>
  api.getUserActiveSubscriptionDetails()
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    clearError(state) {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribe.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error occurred";
        state.isLoading = false;
      })
      .addCase(subscribe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subscribe.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.message) {
          state.error = action.payload.message;
        } else {
          state.stripeSessionUrl = action.payload;
        }
      })

      .addCase(getUserActiveSubscriptionDetails.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error occurred";
        state.isLoading = false;
      })
      .addCase(getUserActiveSubscriptionDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserActiveSubscriptionDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.message) {
          state.error = action.payload.message;
        } else {
          state.subscription.id = action.payload.id;
          state.subscription.status = action.payload.status;
          state.subscription.priceType = action.payload.priceType;
          state.subscription.currency = action.payload.currency;
          state.subscription.amount = action.payload.amount;
          state.subscription.startDate = action.payload.startDate;
          state.subscription.endDate = action.payload.endDate;
          state.subscription.tariff = action.payload.tariff;
        }
      });
  },
});

export const { clearError } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
