/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./workPage.css";
import { useDispatch } from "react-redux";
import { setOnVideo } from "../../synthetic/syntheticParamsSlice";
import { hidePopup } from "../../synthetic/syntheticSlice";

function WorkPage() {
  const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const inputRefs = useMemo(() => Array.from({ length: 4 }, () => React.createRef()), []);
  const [currentVideoDuration, setCurrentVideoDuration] = useState(0);
  const [isPlay, setIsPlay] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoArrayEn = [
    {
      videoPath: `${videoUrl}/SynWorkUploadEn.mp4`,
      question: false,
      duration: 22000,
    },
    {
      videoPath: `${videoUrl}/SynWorkSceneEn.mp4`,
      question: false,
      duration: 18000,
    },
    {
      videoPath: `${videoUrl}/SynWorkVoiceEn.mp4`,
      question: false,
      duration: 15000,
    },
    {
      videoPath: `${videoUrl}/SynWorkKnowledgeEn.mp4`,
      question: false,
      duration: 17000,
    },
  ];
  const videoArrayDe = [
    {
      videoPath: `${videoUrl}/SynWorkUploadDe.mp4`,
      question: false,
      duration: 29000,
    },
    {
      videoPath: `${videoUrl}/SynWorkSceneDe.mp4`,
      question: false,
      duration: 22000,
    },
    {
      videoPath: `${videoUrl}/SynWorkVoiceDe.mp4`,
      question: false,
      duration: 18000,
    },
    {
      videoPath: `${videoUrl}/SynWorkKnowledgeDe.mp4`,
      question: false,
      duration: 19000,
    },
  ];
  const videoArrayFi = [
    {
      videoPath: `${videoUrl}/SynWorkUploadFi.mp4`,
      question: false,
      duration: 26000,
    },
    {
      videoPath: `${videoUrl}/SynWorkSceneFi.mp4`,
      question: false,
      duration: 21000,
    },
    {
      videoPath: `${videoUrl}/SynWorkVoiceFi.mp4`,
      question: false,
      duration: 17000,
    },
    {
      videoPath: `${videoUrl}/SynWorkKnowledgeFi.mp4`,
      question: false,
      duration: 20000,
    },
  ];
  const videoArrayFr = [
    {
      videoPath: `${videoUrl}/SynWorkUploadFr.mp4`,
      question: false,
      duration: 29000,
    },
    {
      videoPath: `${videoUrl}/SynWorkSceneFr.mp4`,
      question: false,
      duration: 21000,
    },
    {
      videoPath: `${videoUrl}/SynWorkVoiceFr.mp4`,
      question: false,
      duration: 16000,
    },
    {
      videoPath: `${videoUrl}/SynWorkKnowledgeFr.mp4`,
      question: false,
      duration: 19000,
    },
  ];
  const videoArrayEs = [
    {
      videoPath: `${videoUrl}/SynWorkUploadEs.mp4`,
      question: false,
      duration: 25000,
    },
    {
      videoPath: `${videoUrl}/SynWorkSceneEs.mp4`,
      question: false,
      duration: 21000,
    },
    {
      videoPath: `${videoUrl}/SynWorkVoiceEs.mp4`,
      question: false,
      duration: 17000,
    },
    {
      videoPath: `${videoUrl}/SynWorkKnowledgeEs.mp4`,
      question: false,
      duration: 19000,
    },
  ];

  const handleVideoCard = (currIndex) => {
    let newOnVideo;
    switch (i18n.language) {
      case "en":
        newOnVideo = videoArrayEn[currIndex];
        break;
      case "de":
        newOnVideo = videoArrayDe[currIndex];
        break;
      case "fi":
        newOnVideo = videoArrayFi[currIndex];
        break;
      case "fr":
        newOnVideo = videoArrayFr[currIndex];
        break;
      case "es":
        newOnVideo = videoArrayEs[currIndex];
        break;
      default:
        newOnVideo = videoArrayEn[currIndex];
        break;
    }
    if (isPlay) {
      dispatch(setOnVideo(newOnVideo));
    }
    setCurrentVideoDuration(newOnVideo.duration);
  };

  const handlePause = () => {
    setIsPlay(false);
    dispatch(hidePopup());
  };

  useEffect(() => {
    if (isPlay) {
      handleVideoCard(currentIndex);
    } else {
      handlePause();
    }
  }, [currentIndex, isPlay]);

  useEffect(() => {
    const imageIntervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 5);
    }, 3000);

    return () => {
      clearInterval(imageIntervalId);
    };
  }, [currentIndex, inputRefs, isPlay]);

  return (
    <div className="mainBody">
      <div className="headerTextContainer">
        <p className="heading">{t("worksHeading")}</p>
      </div>
      <div ref={containerRef} className="refBox">
        <div className="tabsContainer" id="tabsContainer">
          <input type="radio" name="slider" id="item-1" ref={inputRefs[0]} defaultChecked />
          <input type="radio" name="slider" id="item-2" ref={inputRefs[1]} />
          <input type="radio" name="slider" id="item-3" ref={inputRefs[2]} />
          <input type="radio" name="slider" id="item-4" ref={inputRefs[3]} />
          <div className="cardsContainer">
            <button type="button" className="imagesContainer" htmlFor="item-1" id="img-1">
              <div className="parentContainer">
                <div className="textContainer">
                  <p className="textUpper">{t("textUpperPhoto")}</p>
                  <p className="textH2">{t("textH2Photo")}</p>
                </div>
                <div className="textLowerContainer">
                  <p className="textLower">{t("textLowerPhoto")}</p>
                </div>
              </div>
              <div className="imageContainer">
                <img
                  className={`image ${currentImageIndex === 0 ? "visible" : ""}`}
                  src="/illustrationPhoto1.webp"
                  alt="illustration1"
                />
                <img
                  className={`image ${currentImageIndex === 1 ? "visible" : ""}`}
                  src="/illustrationPhoto2.webp"
                  alt="illustration2"
                />
                <img
                  className={`image ${currentImageIndex === 2 ? "visible" : ""}`}
                  src="/illustrationPhoto3.webp"
                  alt="illustration3"
                />
                <img
                  className={`image ${currentImageIndex === 3 ? "visible" : ""}`}
                  src="/illustrationPhoto4.webp"
                  alt="illustration4"
                />
                <img
                  className={`image ${currentImageIndex === 4 ? "visible" : ""}`}
                  src="/illustrationPhoto5.webp"
                  alt="illustration5"
                />
              </div>
            </button>
            <button
              type="button"
              className="tab"
              htmlFor="item-1"
              id="img-photo"
              onClick={() => {
                setIsPlay(true);
                setCurrentIndex(0);
                inputRefs[0].current.checked = true;
                handleVideoCard(0);
              }}
            >
              {t("Photo")}
            </button>
            <button type="button" className="tabShadow" htmlFor="item-1">
              {t("Photo")}
            </button>

            <button type="button" className="imagesContainer" htmlFor="item-2" id="img-2">
              <div className="parentContainer">
                <div className="textContainer">
                  <p className="textUpper">{t("textUpperScene")}</p>
                  <p className="textH2">{t("textH2Scene")}</p>
                </div>
                <div className="textLowerContainer">
                  <p className="textLower">{t("textLowerScene")}</p>
                </div>
              </div>
              <div className="imageContainer">
                <img
                  className={`image ${currentImageIndex === 0 ? "visible" : ""}`}
                  src="/IllustrationScene1.webp"
                  alt="scene1"
                />
                <img
                  className={`image ${currentImageIndex === 1 ? "visible" : ""}`}
                  src="/IllustrationScene2.webp"
                  alt="scene2"
                />
                <img
                  className={`image ${currentImageIndex === 2 ? "visible" : ""}`}
                  src="/IllustrationScene3.webp"
                  alt="scene3"
                />
                <img
                  className={`image ${currentImageIndex === 3 ? "visible" : ""}`}
                  src="/IllustrationScene4.webp"
                  alt="scene4"
                />
                <img
                  className={`image ${currentImageIndex === 4 ? "visible" : ""}`}
                  src="/IllustrationScene5.webp"
                  alt="scene5"
                />
              </div>
            </button>
            <button
              type="button"
              className="tab"
              htmlFor="item-2"
              id="img-Scene"
              onClick={() => {
                setIsPlay(true);
                setCurrentIndex(1);
                inputRefs[1].current.checked = true;
                handleVideoCard(1);
              }}
            >
              {t("Scene")}
            </button>
            <button type="button" className="tabShadow" htmlFor="item-2">
              {t("Scene")}
            </button>

            <button type="button" className="imagesContainer" htmlFor="item-3" id="img-3">
              <div className="parentContainer">
                <div className="textContainer">
                  <p className="textUpper">{t("textUpperVoice")}</p>
                  <p className="textH2">{t("textH2Voice")}</p>
                </div>
                <div className="textLowerContainer">
                  <p className="textLower">{t("textLowerVoice")}</p>
                </div>
              </div>
              <div className="imageContainer">
                <img
                  className={`image ${currentImageIndex === 0 ? "visible" : ""}`}
                  src="/IllustrationVoice1.webp"
                  alt="voice1"
                />
                <img
                  className={`image ${currentImageIndex === 1 ? "visible" : ""}`}
                  src="/IllustrationVoice2.webp"
                  alt="voice2"
                />
                <img
                  className={`image ${currentImageIndex === 2 ? "visible" : ""}`}
                  src="/IllustrationVoice3.webp"
                  alt="voice3"
                />
                <img
                  className={`image ${currentImageIndex === 3 ? "visible" : ""}`}
                  src="/IllustrationVoice4.webp"
                  alt="voice4"
                />
                <img
                  className={`image ${currentImageIndex === 4 ? "visible" : ""}`}
                  src="/IllustrationVoice5.webp"
                  alt="voice5"
                />
              </div>
            </button>
            <button
              type="button"
              className="tab"
              htmlFor="item-3"
              id="img-Voice"
              onClick={() => {
                setIsPlay(true);
                setCurrentIndex(2);
                inputRefs[2].current.checked = true;
                handleVideoCard(2);
              }}
            >
              {t("Voice")}
            </button>
            <button type="button" className="tabShadow" htmlFor="item-3">
              {t("Voice")}
            </button>

            <button type="button" className="imagesContainer" htmlFor="item-4" id="img-4">
              <div className="parentContainer">
                <div className="textContainer">
                  <p className="textUpper">{t("textUpperBase")}</p>
                  <p className="textH2">{t("textH2Base")}</p>
                </div>
                <div className="textLowerContainer">
                  <p className="textLower">{t("textLowerBase")}</p>
                </div>
              </div>
              <div className="imageContainer">
                <img
                  className={`image ${currentImageIndex === 0 ? "visible" : ""}`}
                  src="/IllustrationBase1.webp"
                  alt="base1"
                />
                <img
                  className={`image ${currentImageIndex === 1 ? "visible" : ""}`}
                  src="/IllustrationBase2.webp"
                  alt="base2"
                />
                <img
                  className={`image ${currentImageIndex === 2 ? "visible" : ""}`}
                  src="/IllustrationBase3.webp"
                  alt="base3"
                />
                <img
                  className={`image ${currentImageIndex === 3 ? "visible" : ""}`}
                  src="/IllustrationBase4.webp"
                  alt="base4"
                />
                <img
                  className={`image ${currentImageIndex === 4 ? "visible" : ""}`}
                  src="/IllustrationBase5.webp"
                  alt="base5"
                />
              </div>
            </button>
            <button
              type="button"
              className="tab"
              htmlFor="item-4"
              id="img-Base"
              onClick={() => {
                setIsPlay(true);
                setCurrentIndex(3);
                inputRefs[3].current.checked = true;
                handleVideoCard(3);
              }}
            >
              {t("Base")}
            </button>
            <button type="button" className="tabShadow" htmlFor="item-4">
              {t("Base")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkPage;
