// eslint-disable-next-line no-unused-vars
import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccountValidationSchema, initialValues } from "../../../forms/AccountForm";
import AccountFields from "../account_fields/AccountFields";
import { selectError, selectUser } from "../../auth/selectors";
import { update } from "../../auth/authSlice";
import styles from "./AddAccountInfo.module.css";
import "../../../synthetic/synthetic.css";
import deepEqual from "../../../utils/deepEqual";
import Spinner from "./Spinner";

const AddAccountInfo = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const error = useSelector(selectError);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    country,
    fullName,
    street,
    house,
    apartment,
    postalCode,
    city,
    phoneNumber,
    email,
    vatcompany,
    companyName,
    isBusinessPurchase,
    ipaddress,
  } = user;

  const initialFieldValues = initialValues(
    country,
    fullName,
    street,
    house,
    apartment,
    postalCode,
    city,
    phoneNumber,
    email,
    vatcompany,
    companyName,
    isBusinessPurchase,
    ipaddress
  );

  const handleSave = async (formValues) => {
    if (deepEqual(formValues, initialFieldValues)) {
      await onSubmit();
      return;
    }
    const params = { ...formValues, id: user.id };
    const dispatchResult = await dispatch(update(params));
    if (update.fulfilled.match(dispatchResult)) {
      await onSubmit();
    }
  };

  return (
    <Formik
      initialValues={initialFieldValues}
      validationSchema={AccountValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        setIsSubmitting(true);
        handleSave(formValues).finally(() => {
          setSubmitting(false);
          setIsSubmitting(false);
        });
      }}
    >
      {({ handleSubmit, setFieldValue, handleChange, handleBlur, values, errors, touched, setTouched }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setTouched({
              fullName: true,
              country: true,
              city: true,
              street: true,
              house: true,
              postalCode: true,
              phoneNumber: true,
              email: true,
              ipaddress: true,
            });
            handleSubmit(e);
          }}
        >
          {isSubmitting && <Spinner />}
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <div className={styles.closeIconContainer}>
                <i
                  className={`cl-btn-7 ${styles.closeIcon}`}
                  onClick={onClose}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      onClose();
                    }
                  }}
                  aria-label="Close"
                />
              </div>

              <p className={styles.title}>Please Fill/Verify Your Information</p>
              <AccountFields
                values={values}
                initialValues={initialFieldValues}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
              />
              <div className={styles.buttonsContainer}>
                <button type="submit">{t("accept")}</button>
                <button type="button" onClick={onClose}>
                  {t("decline")}
                </button>
              </div>
              {error && <span className={styles.error}>{error}</span>}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo(AddAccountInfo);

AddAccountInfo.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddAccountInfo.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
};
