import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./faqBlock.module.css";
import { setOnVideo } from "../../../../synthetic/syntheticParamsSlice";
import { hidePopup } from "../../../../synthetic/syntheticSlice";

function FAQBlock() {
  const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTextKey, setModalTextKey] = useState("");

  const openModal = (textKey) => {
    setModalTextKey(textKey);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(hidePopup());
  };

  const videoArrayEn = [
    {
      videoPath: `${videoUrl}/SynFaqUploadEn.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLimitEn.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLanguageEn.mp4`,
      question: false,
    },
  ];
  const videoArrayDe = [
    {
      videoPath: `${videoUrl}/SynFaqUploadDe.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLimitDe.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLanguageDe.mp4`,
      question: false,
    },
  ];
  const videoArrayFi = [
    {
      videoPath: `${videoUrl}/SynFaqUploadFi.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLimitFi.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLanguageFi.mp4`,
      question: false,
    },
  ];
  const videoArrayFr = [
    {
      videoPath: `${videoUrl}/SynFaqUploadFr.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLimitFr.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLanguageFr.mp4`,
      question: false,
    },
  ];
  const videoArrayEs = [
    {
      videoPath: `${videoUrl}/SynFaqUploadEs.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLimitEs.mp4`,
      question: false,
    },
    {
      videoPath: `${videoUrl}/SynFaqLanguageEs.mp4`,
      question: false,
    },
  ];

  const handleVideoCard = (currIndex) => {
    let newOnVideo;
    switch (i18n.language) {
      case "en":
        newOnVideo = videoArrayEn[currIndex];
        break;
      case "de":
        newOnVideo = videoArrayDe[currIndex];
        break;
      case "fi":
        newOnVideo = videoArrayFi[currIndex];
        break;
      case "fr":
        newOnVideo = videoArrayFr[currIndex];
        break;
      case "es":
        newOnVideo = videoArrayEs[currIndex];
        break;
      default:
        newOnVideo = videoArrayEn[currIndex];
        break;
    }
    dispatch(setOnVideo(newOnVideo));
  };

  return (
    <div id="faq" className={styles.mainBody}>
      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={closeModal} role="button" tabIndex="0">
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()} role="button" tabIndex="0">
            <button type="button" className={styles.closeButton} onClick={closeModal}>
              ×
            </button>
            <div className={styles.modalText}>{t(modalTextKey)}</div>
          </div>
        </div>
      )}
      <div className={styles.mainContainer}>
        <div className={styles.faqContainer}>
          <div className={styles.faqTextContainer}>
            <div className={styles.faqHeaderTextContainer}>
              <p className={styles.faqHeading}>{t("faqHeading")}</p>
            </div>
            <div className={styles.faqSubtitleContainer}>
              <button
                type="button"
                className={styles.faqParagraphContainer}
                onClick={() => {
                  openModal("faqParagraph1Key");
                  handleVideoCard(0);
                }}
              >
                <img className={styles.frameContainer} src="/faqIcon.webp" alt="frame" />
                <div className={styles.faqParagraph}>
                  <p className={styles.faqParagraphText}>{t("faqParagraph1")}</p>
                </div>
              </button>
              <button
                type="button"
                className={styles.faqParagraphContainer}
                onClick={() => {
                  openModal("faqParagraph2Key");
                  handleVideoCard(1);
                }}
              >
                <img className={styles.frameContainer} src="/faqIcon.webp" alt="frame" />
                <div className={styles.faqParagraph}>
                  <p className={styles.faqParagraphText}>{t("faqParagraph2")}</p>
                </div>
              </button>
              <button
                type="button"
                className={styles.faqParagraphContainer}
                onClick={() => {
                  openModal("faqParagraph3Key");
                  handleVideoCard(2);
                }}
              >
                <img className={styles.frameContainer} src="/faqIcon.webp" alt="frame" />
                <div className={styles.faqParagraph}>
                  <p className={styles.faqParagraphText}>{t("faqParagraph3")}</p>
                </div>
              </button>
            </div>

            <button className={styles.buttonSeeMoreFAQs} type="button">
              <div className={styles.buttonContainer}>
                <Link to="/faq" id="faqPage">
                  <span>{t("buttonSeeMoreFAQs")}</span>
                  <img src="/arrow-right.webp" alt="arrow-right" />
                </Link>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQBlock;
