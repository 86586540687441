import { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AddAccountInfo from "../account/add_account_info/AddAccountInfo";
import Button from "../../components/button/Button";
import { subscribe } from "./subscriptionSlice";
import { selectIsAuth } from "../auth/selectors";
import { selectSubscription } from "./selectors";

import styles from "./Subscription.module.css";
import buttonStyle from "../../components/button/button.module.css";

const Subscription = (props) => {
  const { isProTariff, isRecurringPrice } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAccountInfoModalOpened, setIsAccountInfoModalOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isAuth = useSelector(selectIsAuth);
  const subscription = useSelector(selectSubscription);

  useEffect(() => {
    setErrorMessage("");
  }, [isRecurringPrice]);

  const handleClick = useCallback(async () => {
    setErrorMessage("");
    if (!isAuth) {
      navigate("/signUp");
      return;
    }

    if (!subscription?.id) {
      setIsAccountInfoModalOpened(true);
      return;
    }

    if (subscription?.priceType === "RECURRING" && subscription?.tariff === "PRO") {
      setErrorMessage(t("errorMaxSubTariff"));
      return;
    }

    if (!isRecurringPrice) {
      if (subscription?.priceType === "RECURRING" || subscription?.tariff === "PRO") {
        setErrorMessage(t("errorSubTariff"));
        return;
      }

      if (!isProTariff) {
        setErrorMessage(t("errorSubTariff"));
        return;
      }
    }

    if (isRecurringPrice && !isProTariff && subscription?.priceType === "RECURRING" && subscription?.tariff !== "PRO") {
      setErrorMessage(t("errorSubTariff"));
      return;
    }

    setIsAccountInfoModalOpened(true);
  }, [isAuth, isProTariff, isRecurringPrice, navigate, subscription, t]);

  const handleSubmit = useCallback(async () => {
    const data = { isProTariff, isRecurringPrice };
    const actionResult = await dispatch(subscribe(data));

    if (subscribe.fulfilled.match(actionResult)) {
      window.location.href = actionResult.payload;
    }
  }, [dispatch, isProTariff, isRecurringPrice]);

  return (
    <>
      <div className={styles.container}>
        <Button
          buttonName={t("subscribeBtn")}
          buttonType="submit"
          onClick={handleClick}
          className={buttonStyle.buttonGlobal}
        />
      </div>
      <span className={styles.errorMessage}>{errorMessage}</span>

      {isAccountInfoModalOpened && (
        <AddAccountInfo onClose={() => setIsAccountInfoModalOpened(false)} onSubmit={handleSubmit} />
      )}
    </>
  );
};

Subscription.propTypes = {
  isProTariff: PropTypes.bool.isRequired,
  isRecurringPrice: PropTypes.bool.isRequired,
};

export default memo(Subscription);
