import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import styles from "./privacy.module.css";
import i18n from "../../locales/i18n";

export default function Privacy() {
  const { t } = useTranslation();
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      document.documentElement.lang = lng;
    };
    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className={styles.pageBox}>
      <div className={styles.contextPageBox}>
        <h1 className={styles.titlePage}>{t("privacyPolicy")}</h1>
        <p className={styles.textVer}>{t("versionGDPR")}</p>
        <h3 className={styles.titleParagraph}>{t("registrar")}</h3>
        <p className={styles.text}>Siltacom Oy</p>
        <p className={styles.text}>{t("businessID")}: 2917655-2</p>
        <p className={styles.text}>Email: info@siltacom.fi</p>
        <h3 className={styles.titleParagraph}>{t("registered")}</h3>
        <p className={styles.text}>{t("dataSubject")}</p>
        <h3 className={styles.titleParagraph}>{t("customer")}</h3>
        <p className={styles.text}>{t("registeredCompany")}</p>
        <h3 className={styles.titleParagraph}>{t("personalRegisterName")}</h3>
        <p className={styles.text}>{t("userRegister")}</p>
        <h3 className={styles.titleParagraph}>{t("dataProcessingBasisAndPurpose")}</h3>
        <p className={styles.text}>{t("processingLegalBasis")}</p>
        <h3 className={styles.titleParagraph}>{t("infoSources")}</h3>
        <p className={styles.text}>{t("registerInfo")}</p>
        <p className={styles.text}>{t("name")}</p>
        <p className={styles.text}>Email</p>
        <p className={styles.text}>{t("phoneNumber")}</p>
        <p className={styles.text}>{t("productInfo")}</p>
        <h3 className={styles.titleParagraph}>{t("personalInfoDisclosure")}</h3>
        <p className={styles.text}>{t("noDisclosureRule")}</p>
        <h3 className={styles.titleParagraph}>{t("dataProtection")}</h3>
        <p className={styles.text}>{t("dataProtectionMethods")}</p>
        <h3 className={styles.titleParagraph}>{t("dataStoragePeriod")}</h3>
        <p className={styles.text}>{t("storageDuration")}</p>
        <h3 className={styles.titleParagraph}>{t("profiling")}</h3>
        <p className={styles.text}>{t("noProfiling")}</p>
        <h3 className={styles.titleParagraph}>{t("registrantRights")}</h3>
        <p className={styles.subItemName}>{t("accessRight")}</p>
        <p className={styles.text}>{t("confirmationRight")}</p>
        <p className={styles.subItemName}>{t("rectificationRight")}</p>
        <p className={styles.text}>{t("rectificationRequest")}</p>
        <p className={styles.subItemName}>{t("deleteDataRight")}</p>
        <p className={styles.text}>{t("deleteRequest")}</p>
        <p className={styles.text}>a. {t("deleteReasonNoLongerNeeded")}</p>
        <p className={styles.text}>b. {t("deleteReasonWithdrawConsent")}</p>
        <p className={styles.text}>c. {t("deleteReasonProcessedIllegally")}</p>
        <p className={styles.subItemName}>{t("restrictProcessingRight")}</p>
        <p className={styles.text}>{t("restrictProcessingRequest")}</p>
        <p className={styles.text}>a. {t("restrictProcessingDenyAccuracy")}</p>
        <p className={styles.text}>b. {t("restrictProcessingAgainstLaw")}</p>
        <p className={styles.text}>c. {t("restrictProcessingControllerNoLongerNeeds")}</p>
        <p className={styles.subItemName}>{t("dataTransferRight")}</p>
        <p className={styles.text}>{t("dataTransferRequest")}</p>
        <p className={styles.subItemName}>{t("complaintRight")}</p>
        <p className={styles.text}>{t("complaintInfo")}</p>
        <h3 className={styles.titleParagraph}>{t("contacts")}</h3>
        <p className={styles.text}>{t("contactInstructions")}</p>
      </div>
    </div>
  );
}
