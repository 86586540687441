import { useTranslation } from "react-i18next";
import styles from "./techBlock.module.css";

function TechBlock() {
  const { t } = useTranslation();

  return (
    <div className={styles.mainBody}>
      <div className={styles.mainContainer}>
        <div className={styles.textContainer}>
          <div className={styles.headerTextContainer}>
            <p className={styles.heading}>{t("techHeading")}</p>
          </div>
          <div className={styles.textBlockContainer}>
            <div className={styles.textLeftContainer}>
              <p className={styles.textHeading}>{t("techLanguageHeading")}</p>
              <p className={styles.text}>{t("techLanguage")}</p>
            </div>
            <div className={styles.textRightContainer}>
              <p className={styles.textHeading}>{t("techAlgorithmsHeading")}</p>
              <p className={styles.text}>{t("techAlgorithms")}</p>
            </div>
          </div>
          <div className={styles.textBlockContainer}>
            <div className={styles.textLeftContainer}>
              <p className={styles.textHeading}>{t("techInfrastructureHeading")}</p>
              <p className={styles.text}>{t("techInfrastructure")}</p>
            </div>
            <div className={styles.textRightContainer}>
              <p className={styles.textHeading}>{t("techSecurityHeading")}</p>
              <p className={styles.text}>{t("techSecurity")}</p>
            </div>
          </div>
          <div className={styles.textBlockContainer}>
            <div className={styles.textLeftContainer}>
              <p className={styles.textHeading}>{t("techApiHeading")}</p>
              <p className={styles.text}>{t("techApi")}</p>
            </div>
            <div className={styles.textRightContainer}>
              <p className={styles.textHeading}>{t("techAnalyticsHeading")}</p>
              <p className={styles.text}>{t("techAnalytics")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechBlock;
