import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import * as api from "./api"

const initialState = {
  user: undefined,
  isAuth: false,
  isLoading: false,
  userRegistr: {
    fullName: "",
    email: "",
    phoneNumber: "",
  },
  error: false,
  errorMessage: "",
  errorExistMessage: "",
};

export const getPricing = createAsyncThunk("getPricing", async () => api.getAllPricing());

export const getPricingConfig = createAsyncThunk("getPricingConfig", async () => api.getAllPricingConfig());

const priceSlice = createSlice({
    name: "price",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
      builder
      .addCase(getPricingConfig.rejected, (state, action) => {
        state.errorMessage = action.error.message;
        state.isLoading = false;
      })
      .addCase(getPricingConfig.pending, (state) => {
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(getPricingConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        if (action.payload.message) {
          state.errorExistMessage = action.payload.message;
        } else {
          state.user = action.payload;
          state.errorExistMessage = "";
        }
      })
      .addCase(getPricing.rejected, (state, action) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(getPricing.pending, (state) => {
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(getPricing.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.message) {
          state.errorExistMessage = action.payload.message;
        } else {
          state.user = action.payload;
          state.errorExistMessage = "";
        }
      })
    },
  });
  
  export const { } =
    priceSlice.actions;
  export default priceSlice.reducer;
  