import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./footer.module.css";
import { setLanguage } from "../../features/language_selector/languageSlice";
import { setVideoClick } from "../../synthetic/syntheticParamsSlice";

export default function Footer() {
  const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch();

  const handleLanguageChange = async (language) => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
    localStorage.setItem("language", language);

    let newVideoClick;
    switch (language) {
      case "en":
        newVideoClick = {
          videoPath: `${videoUrl}/SynIntroEn.mp4`,
          question: false,
        };
        break;
      case "de":
        newVideoClick = {
          videoPath: `${videoUrl}/SynIntroDe.mp4`,
          question: false,
        };
        break;
      case "fi":
        newVideoClick = {
          videoPath: `${videoUrl}/SynIntroFi.mp4`,
          question: false,
        };
        break;
      case "fr":
        newVideoClick = {
          videoPath: `${videoUrl}/SynIntroFr.mp4`,
          question: false,
        };
        break;
      case "es":
        newVideoClick = {
          videoPath: `${videoUrl}/SynIntroEs.mp4`,
          question: false,
        };
        break;
      default:
        newVideoClick = {
          videoPath: `${videoUrl}/SynIntroEn.mp4`,
          question: false,
        };
        break;
    }
    dispatch(setVideoClick(newVideoClick));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSocialClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={styles.footerBox} id="footerBox">
      <div className={styles.contextFooterBox}>
        <div className={styles.navbarBox}>
          <Link to="/pricing" id="pricingButton" className={location.pathname === "/pricing" ? styles.activeLink : ""}>
            {t("pricing")}
          </Link>
          <Link to="/faq" id="faqButton" className={location.pathname === "/faq" ? styles.activeLink : ""}>
            FAQ
          </Link>
          <Link to="/blog" id="blogLink" className={location.pathname === "/blog" ? styles.activeLink : ""}>
            Blog
          </Link>
          <Link
            to="/contactUs"
            id="contactLink"
            className={location.pathname === "/contactUs" ? styles.activeLink : ""}
          >
            {t("contacts")}
          </Link>
        </div>
        <div className={styles.langBox}>
          <span className={styles.titleLangBox}>{t("siteLanguage")}</span>
          <button
            type="button"
            onClick={() => handleLanguageChange("en")}
            id="langEnButton"
            className={i18n.language === "en" ? styles.activeButton : ""}
          >
            English
          </button>
          <button
            type="button"
            onClick={() => handleLanguageChange("de")}
            id="langDeButton"
            className={i18n.language === "de" ? styles.activeButton : ""}
          >
            Deutsch
          </button>
          <button
            type="button"
            onClick={() => handleLanguageChange("fi")}
            id="langFiButton"
            className={i18n.language === "fi" ? styles.activeButton : ""}
          >
            Finnish
          </button>
          <button
            type="button"
            onClick={() => handleLanguageChange("fr")}
            id="langFrButton"
            className={i18n.language === "fr" ? styles.activeButton : ""}
          >
            Français
          </button>
          <button
            type="button"
            onClick={() => handleLanguageChange("es")}
            id="langEsButton"
            className={i18n.language === "es" ? styles.activeButton : ""}
          >
            Español
          </button>
        </div>
        <p className={styles.sepLine} />
        <div className={styles.bottomInfoBox}>
          <span>© 2024 Synthetic Inc. (v1.5.1)</span>
          <Link to="/terms" id="termsLink">
            {t("termsAndConditions")}
          </Link>
          <Link to="/privacy" id="privacyLink">
            {t("privacyPolicy")}
          </Link>
          <div className={styles.bottomInfoBoxButtons}>
            <button type="button" id="facebookButton">
              <img src="/facebook.webp" alt="facebook" />
            </button>
            <button type="button" id="instagramButton">
              <img src="/instagram.webp" alt="instagram" />
            </button>
            <button type="button" id="twitterButton">
              <img src="/twitter.webp" alt="X" />
            </button>
            <button
              type="button"
              onClick={() => handleSocialClick(" https://www.linkedin.com/company/artificial-synthetic")}
              id="linkedinButton"
            >
              <img src="/linkedin.webp" alt="linkedin" />
            </button>
            <button type="button" id="tiktokButton">
              <img src="/tiktok.webp" alt="tik-tok" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
