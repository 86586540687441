/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import styles from "./featureSelector.module.css";

function FeatureSelector() {
  const location = useLocation();
  const [dropdownList, setDropdownList] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setDropdownList(false);
  }, [location.pathname]);

  return (
    <div ref={dropdownRef} className={styles.customDropdown}>
      <button
        type="button"
        className={
          location.pathname === "/" || window.innerWidth <= 768 ? styles.dropdownHeaderHome : styles.dropdownHeaderOther
        }
        onClick={() => setDropdownList(!dropdownList)}
      >
        <img
          // src={location.pathname === "/" || window.innerWidth <= 768 ? `/feature-home.png` : `/feature-other.png`}
          src={window.innerWidth <= 768 ? `/feature-other.png` : `/feature-home.png`}
          alt="feature"
        />
        Features
      </button>
      {dropdownList && (
        <div
          className={
            location.pathname === "/" || window.innerWidth <= 768
              ? styles.dropdownListActiveHome
              : styles.dropdownListActiveOther
          }
        >
          <div className={styles.dropdownSection}>
            <Link to="/howitwork" className={styles.dropdownItem}>
              How it works
            </Link>
          </div>
          <div className={styles.dropdownSection}>
            <Link to="/testyourlanguage" className={styles.dropdownItem}>
              Test languages
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeatureSelector;
