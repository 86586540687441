import { memo } from "react";
import PropTypes from "prop-types";

import styles from "./Input.module.css";

const Input = ({ label, name, type, value, onChange, onBlur, error, required, placeholder, readOnly }) => (
  <div className={styles.container}>
    <label className={styles.label}>{label}</label>
    <input
      name={name}
      type={type}
      className={styles.input}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      onBlur={onBlur}
      readOnly={readOnly}
    />
    {error && <p className={styles.error}>{error}</p>}
  </div>
);

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  type: "text",
  error: "",
  required: false,
  placeholder: "",
  readOnly: false,
};

export default memo(Input);
