/* eslint-disable no-console */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import languages json
import translationEN from "./en/translation.json";
import translationDE from "./de/translation.json";
import translationFI from "./fi/translation.json";
import translationFR from "./fr/translation.json";
import translationES from "./es/translation.json";

const resources = {
  // Add similar objects for other languages
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  fi: {
    translation: translationFI,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    fallbackLng: "en", // default fallback language
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {})
  .catch((error) => {
    console.error("Failed to initialize i18n:", error);
  });

export default i18n;
