import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./cookies.module.css";
import { setOnVideo } from "../../synthetic/syntheticParamsSlice";

function Cookies() {
  const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleVideo = (btn) => {
    let newOnVideo;
    switch (i18n.language) {
      case "en":
        if (btn === "accept") {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieAcceptEn.mp4`,
            videoPath: `${videoUrl}/SynIntroEn.mp4`,
            question: false,
          };
        } else {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieDeclineEn.mp4`,
            videoPath: `${videoUrl}/SynIntroEn.mp4`,
            question: false,
          };
        }
        break;
      case "de":
        if (btn === "accept") {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieAcceptDe.mp4`,
            videoPath: `${videoUrl}/SynIntroDe.mp4`,
            question: false,
          };
        } else {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieDeclineDe.mp4`,
            videoPath: `${videoUrl}/SynIntroDe.mp4`,
            question: false,
          };
        }
        break;
      case "fi":
        if (btn === "accept") {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieAcceptFi.mp4`,
            videoPath: `${videoUrl}/SynIntroFi.mp4`,
            question: false,
          };
        } else {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieDeclineFi.mp4`,
            videoPath: `${videoUrl}/SynIntroFi.mp4`,
            question: false,
          };
        }
        break;
      case "fr":
        if (btn === "accept") {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieAcceptFr.mp4`,
            videoPath: `${videoUrl}/SynIntroFr.mp4`,
            question: false,
          };
        } else {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieDeclineFr.mp4`,
            videoPath: `${videoUrl}/SynIntroFr.mp4`,
            question: false,
          };
        }
        break;
      case "es":
        if (btn === "accept") {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieAcceptEs.mp4`,
            videoPath: `${videoUrl}/SynIntroEs.mp4`,
            question: false,
          };
        } else {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieDeclineEs.mp4`,
            videoPath: `${videoUrl}/SynIntroEs.mp4`,
            question: false,
          };
        }
        break;
      default:
        if (btn === "accept") {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieAcceptEn.mp4`,
            videoPath: `${videoUrl}/SynIntroEn.mp4`,
            question: false,
          };
        } else {
          newOnVideo = {
            // videoPath: `${videoUrl}/SynCookieDeclineEn.mp4`,
            videoPath: `${videoUrl}/SynIntroEn.mp4`,
            question: false,
          };
        }
        break;
    }
    dispatch(setOnVideo(newOnVideo));
  };

  const acceptCookies = () => {
    sessionStorage.setItem("cookieConsent", "accepted");
    setShowCookieConsent(false);
    setShowOverlay(false);
    handleVideo("accept");
  };

  const declineCookies = () => {
    setShowCookieConsent(false);
    setShowOverlay(false);
    handleVideo("decline");
  };

  useEffect(() => {
    const cookieConsent = sessionStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setShowOverlay(true);
      setShowCookieConsent(true);
    }
  }, []);

  return (
    <>
      {showOverlay && <div className={styles.overlay} />}
      {showCookieConsent && (
        <div className={styles.cookieConsentContainer}>
          <img src="/cookies.webp" alt="Cookies" className={styles.cookieImage} />
          <p className={styles.weUseCookies}>{t("weUseCookies")}</p>
          <div className={styles.cookieText}>
            {t("useCookies")}
            <Link to="/privacy" id="privacyLink" target="_blank" rel="noopener noreferrer">
              <p className={styles.privacyPolicy}>{t("privacyPolicy")}</p>
            </Link>
          </div>
          <div className={styles.cookieButtons}>
            <button type="button" onClick={acceptCookies}>
              {t("accept")}
            </button>
            <button type="button" onClick={declineCookies}>
              {t("decline")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Cookies;
