import { useNavigate } from "react-router-dom";
import styles from "./successPage.module.css";

const SuccessPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const onButtonClick = () => {
    navigate("/account/mySynthetic");
  };

  return (
    <div className={styles.center}>
      <div className={styles.vstack}>
        <h1 className={styles.heading}>Success!</h1>
        <p className={styles.text}>{queryParams.toString().split("&").join("\n")}</p>
        <button className={styles.button} onClick={onButtonClick} type="button">
          Go Home
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
