import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./notFound.module.css";

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className={styles.mainBox}>
      <h1 className={styles.notFoundText}>{t("pageNotFound")}</h1>
      <p className={styles.notExistsText}>{t("pageNotExists")}</p>
      <Link className={styles.toHomeBtn} id="goToHomeBtn" to="/">
        <span className={styles.toHomeBtnText}>{t("goToHomePage")}</span>
      </Link>
    </div>
  );
}

export default NotFound;
