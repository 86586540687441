import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  greetingVideo: "",
  videoClick: {
    videoPath: "",
    question: false,
  },
  onYesClick: {
    type: "",
    value: undefined,
  },
  onNoClick: {
    type: "",
    value: undefined,
  },
  onVideo: {
    videoPath: "",
    question: false,
  },
};

const syntheticParamsSlice = createSlice({
  name: "syntheticParams",
  initialState,
  reducers: {
    setGreetingVideo: (state, action) => {
      state.greetingVideo = action.payload;
    },
    setVideoClick: (state, action) => {
      state.videoClick = action.payload;
    },
    setOnYesClick: (state, action) => {
      state.onYesClick = action.payload;
    },
    setOnNoClick: (state, action) => {
      state.onNoClick = action.payload;
    },
    setOnVideo: (state, action) => {
      state.onVideo = action.payload;
    },
  },
});

export const { setGreetingVideo, setVideoClick, setOnYesClick, setOnNoClick, setOnVideo } =
  syntheticParamsSlice.actions;
export default syntheticParamsSlice.reducer;
