// eslint-disable-next-line no-unused-vars
import React from "react";
import styles from "./Spinner.module.css";

const Spinner = () => (
  <div className={styles.spinnerOverlay}>
    <div className={styles.spinner} />
  </div>
);

export default Spinner;
