import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./pricing.module.css";
import { selectAuth } from "../../../../features/auth/selectors.js";
import { useEffect } from "react";
import { getPricing, getPricingConfig } from "../../../price_request/priceSlice.js"

export default function Pricing() {
  const dispatch = useDispatch();
  const [minFree, setMinFree] = useState(0)
  const [minBasic, setMinBasic] = useState(0)
  const [minPro, setMinPro] = useState(0)
  const [priceFree, setPriceFree] = useState("0")
  const [priceBasic, setPriceBasic] = useState("0")
  const [pricePro, setPricePro] = useState("0")
  const [pagesFree, setPagesFree] = useState(0)
  const [pagesBasic, setPagesBasic] = useState(0)
  const [pagesPro, setPagesPro] = useState(0)
  const [discountBasic, setDiscountBasic] = useState(0)
  const [discountPro, setDiscountPro] = useState(0)
  const [domainFree, setDomainFree] = useState(0)
  const [domainBasic, setDomainBasic] = useState(0)
  const [domainPro, setDomainPro] = useState(0)

  const [isMonthOrYear, setIsMonthOrYear] = useState("year");

  async function getData () {
    const pricing = await dispatch(
      getPricing()
    );
    setDiscountBasic(String(pricing.payload[1].discount))
    setDiscountPro(String(pricing.payload[2].discount))
    setPriceFree("€" + String(pricing.payload[0].monthlyPrice))
    setPriceBasic("€"+String(pricing.payload[1].monthlyPrice))
    setPricePro("€"+String(pricing.payload[2].monthlyPrice))
    const pricingConf = await dispatch(
      getPricingConfig()
    );
    setPagesFree(pricingConf.payload[0].pagesPerDomain)
    setPagesBasic(pricingConf.payload[1].pagesPerDomain)
    setPagesPro(pricingConf.payload[2].pagesPerDomain)
    setDomainFree(pricingConf.payload[0].domainsToConnect)
    setDomainBasic(pricingConf.payload[1].domainsToConnect)
    setDomainPro(pricingConf.payload[2].domainsToConnect)
    setMinFree(pricingConf.payload[0].coinsLimitPerMonth*15/60)
    setMinBasic(pricingConf.payload[1].coinsLimitPerMonth*15/60)
    setMinPro(pricingConf.payload[2].coinsLimitPerMonth*15/60)
  }

  useEffect(()=> {
    getData();
  }, [isMonthOrYear])

  const monthlyPriceWithDiscount = (monthlyPrice, discount) => {
    const monthly = parseFloat(monthlyPrice.replace("€", ""));
    const discountedMonthly = Math.round(monthly * (1 - (discount)*0.01));
    return `€${discountedMonthly}`;
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  const auth = useSelector(selectAuth);
  const { isAuth } = auth;

  const handleClickAuth = () => {
    if (isAuth) {
      navigate("/account/mySynthetic");
    } else {
      navigate("/signUp");
    }
  };
  return (
    <div id="price" className={styles.pricingBox}>
      <div className={styles.contextBox}>
        <div className={styles.freeCard}>
          <div className={styles.freeCardContext}>
            <div className={styles.textBox}>
              <p className={styles.titleCard}>{t("starter")}</p>
              <p className={styles.priceCard}>{t("free")}</p>
              <p className={styles.descriptionCard}>{t("seeHowItWork")}</p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.freeBtn}`}
              type="button"
              id="chooseFreePlanButton"
              onClick={handleClickAuth}
            >
              <div className={styles.parentComponent}>
                <span>{t("chooseFree")}</span>
                <img src="/arrow-right-black.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
          <div className={styles.starter}>
            <p className={styles.monthlyPriceText}>{t("createAIAssistant")}</p>
            <p className={styles.sepLineFree} />
            <p className={styles.description1Card}>
              <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
              <span>
                {domainFree} {t("domainsToConnect")}
              </span>
            </p>
            <p className={styles.description1Card}>
              <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
              <span>
                {pagesFree} {t("pagesForOneDomain")}
              </span>
            </p>
            <p className={styles.description1Card}>
              <img src="/circle-check-grau.webp" alt="circle-check" width="25" height="25" />
              <span>
                {minFree} {t("generationTime")}
              </span>
            </p>
          </div>
        </div>
        <div className={styles.buttonBox}>
          <button
            className={`${styles.monthButton} ${isMonthOrYear === "month" ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => setIsMonthOrYear("month")}
            id="monthlyPricingButton"
          >
            {t("monthly")}
          </button>
          <button
            className={`${styles.yearButton} ${isMonthOrYear === "year" ? styles.activeBtn : ""}`}
            type="button"
            onClick={() => setIsMonthOrYear("year")}
            id="yearlyPricingButton"
          >
            {t("yearly")}
          </button>
        </div>
        <div className={styles.basicCard}>
          <div className={styles.basicCardContext}>
            <div className={styles.textBox}>
              <div className={styles.giftBox}>
                <p className={styles.titleCard}>{t("basic")}</p>
                {isMonthOrYear === "year" && (
                  <div className={styles.discountLabel}>
                    <span className={styles.discountText}>-{discountBasic}%</span>
                  </div>
                )}
              </div>
              <div className={`${styles.priceCardBox} ${isMonthOrYear === "year" ? styles.overlap : ""}`}>
                {isMonthOrYear === "year" && (
                  <>
                    <span className={styles.newAnnualPrice}>{monthlyPriceWithDiscount(priceBasic, discountBasic)}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>

                    <div className={styles.monthlyPriceText}>{t("discountedMonthlyPrice")}</div>
                  </>
                )}
                {isMonthOrYear === "month" && (
                  <>
                    <span className={styles.price}>{priceBasic}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                  </>
                )}
              </div>

              <p className={styles.sepLine} />
              <p className={styles.description1Card}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {domainBasic} {t("domainsToConnect")}
                </span>
              </p>
              <p className={styles.description2Card}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {pagesBasic} {t("pagesForOneDomain")}
                </span>
              </p>
              <p className={styles.description3Card}>
                <img src="/circle-check-blau.webp" alt="circle-check" />
                <span>
                  {minBasic} {t("generationTime")}
                </span>
              </p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.basicBtn}`}
              type="button"
              id="chooseBasicPlanButton"
              onClick={() => handleClick("/pricing")}
            >
              <div className={styles.parentComponent}>
                <span>{t("chooseBasic")}</span>
                <img src="/arrow-right-blue.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
        </div>
        <div className={styles.proCard}>
          <div className={styles.proCardContext}>
            <div className={styles.textBox}>
              <div className={styles.giftBox}>
                <p className={styles.titleCard}>Pro</p>
                {isMonthOrYear === "year" && (
                  <div className={styles.discountLabel}>
                    <span className={styles.discountText}>-{discountPro}%</span>
                  </div>
                )}
              </div>

              <div className={`${styles.priceCardBox} ${isMonthOrYear === "year" ? styles.overlap : ""}`}>
                {isMonthOrYear === "year" && (
                  <>
                    <span className={styles.newAnnualPrice}>{monthlyPriceWithDiscount(pricePro, discountPro)}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>

                    <div className={styles.monthlyPriceText}>{t("discountedMonthlyPrice")}</div>
                  </>
                )}

                {isMonthOrYear === "month" && (
                  <>
                    <span className={styles.price}>{pricePro}</span>
                    <span className={styles.descPrice}>{t("perMonth")}</span>
                  </>
                )}
              </div>
              <p className={styles.sepLine} />
              <p className={styles.description1Card}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {domainPro} {t("domainsToConnect")}
                </span>
              </p>
              <p className={styles.description2Card}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {pagesPro} {t("pagesForOneDomain")}
                </span>
              </p>
              <p className={styles.description3Card}>
                <img src="/circle-check-lilla.webp" alt="circle-check" />
                <span>
                  {minPro} {t("generationTime")}
                </span>
              </p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.proBtn}`}
              type="button"
              id="chooseProPlanButton"
              onClick={() => handleClick("/pricing")}
            >
              <div className={styles.parentComponent}>
                <span>{t("chooseAdvanced")}</span>
                <img src="/arrow-right-lilla.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
        </div>
        <div className={styles.enterpriseCard}>
          <div className={styles.enterpriseCardContext}>
            <div className={styles.textBox}>
              <p className={styles.titleCard}>{t("enterprise")}</p>
              <p className={styles.priceCard}>{t("onRequest")}</p>
              <p className={styles.descriptionCard}>{t("customBusinessSupport")}</p>
            </div>
            <button
              className={`${styles.buttonOpenAccount} ${styles.enterpriseBtn}`}
              type="button"
              id="contactSalesButton"
              onClick={() => handleClick("/contactUs")}
            >
              <div className={styles.parentComponent}>
                <span>{t("contactSales")}</span>
                <img src="/arrow-right-orange.webp" alt="arrow-right" />
              </div>
            </button>
          </div>
          <div className={styles.enterpriseImgBox}>
            <img className={styles.enterpriseImg} src="/IllustrationEnterprise.webp" alt="enterprise" />
          </div>
        </div>
        {/* <button className={styles.toCampareBtn} type="button" id="comparePricingPlansButton">
          <span>{t("comparePricingPlans")}</span>
          <img src="/arrow-right-blue.svg" alt="arrow-right" />
          </button> */}
      </div>
    </div>
  );
}
