import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import * as api from "./api";

const initialState = {
  user: undefined,
  isAuth: false,
  isLoading: false,
  userRegistr: {
    fullName: "",
    email: "",
    phoneNumber: "",
  },
  error: false,
  errorMessage: "",
  errorExistMessage: "",
};

export const register = createAsyncThunk("register", async (data) => api.register(data));

export const update = createAsyncThunk("update", async (data) => api.update(data));

export const confirmEmail = createAsyncThunk("confirmEmail", async (confirmBody) => api.confirmEmail(confirmBody));

export const login = createAsyncThunk("login", async (data) => api.login(data));

export const recovery = createAsyncThunk("recovery", async (data) => api.recoveryEmail(data));

export const changePassword = createAsyncThunk("changePassword", async (data) => api.changePassword(data));

export const loadNewAccessToken = createAsyncThunk("loadNewAccessToken", async () => api.newAccessToken());

export const loadAuthUser = createAsyncThunk("loadAuthUser", async () => api.getAuthUser());

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("adminToken");
      state.user = undefined;
      state.isAuth = false;
    },
    setUserRegistr(state, action) {
      state.userRegistr = action.payload;
    },
    clearUserRigistr(state) {
      state.userRegistr = {
        fullName: "",
        email: "",
        phoneNumber: "",
      };
    },
    clearError(state) {
      state.error = false;
    },
    clearErrorMessage(state) {
      state.errorMessage = "";
    },
    clearErrorExistMessage(state) {
      state.errorExistMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.rejected, (state, action) => {
        state.errorMessage = action.error.message;
        state.isLoading = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(recovery.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(recovery.pending, (state) => {
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(recovery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.message) {
          state.errorExistMessage = action.payload.message;
        } else {
          state.user = action.payload;
          state.errorExistMessage = "";
          state.userRegistr = {
            fullName: "",
            email: "",
            phoneNumber: "",
          };
        }
      })
      .addCase(register.rejected, (state, action) => {
        state.errorMessage = action.error.message;
        state.isLoading = false;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.message) {
          state.errorExistMessage = action.payload.message;
        } else {
          state.user = action.payload;
          state.errorExistMessage = "";
          state.userRegistr = {
            fullName: "",
            email: "",
            phoneNumber: "",
          };
        }
      })

      .addCase(update.rejected, (state, action) => {
        state.errorMessage = action.error.message;
        state.isLoading = false;
      })
      .addCase(update.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(update.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.message) {
          state.errorExistMessage = action.payload.message;
        } else {
          state.user = action.payload;
          state.errorExistMessage = "";
        }
      })
      .addCase(confirmEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmEmail.rejected, (state) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(confirmEmail.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.rejected, (state) => {
        state.error = true;
        state.isLoading = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { accessToken, refreshToken, adminToken } = action.payload;
        Cookies.set("accessToken", accessToken, { expires: 1 / 12, secure: true, sameSite: "strict" });
        Cookies.set("refreshToken", refreshToken, { expires: 30, secure: true, sameSite: "strict" });
        Cookies.set("adminToken", adminToken, { expires: 30, secure: true, sameSite: "strict" });
        state.isLoading = false;
        state.isAuth = true;
        state.errorExistMessage = "";
        state.userRegistr = {
          fullName: "",
          email: "",
          phoneNumber: "",
        };
      })
      .addCase(loadNewAccessToken.rejected, (state) => {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        state.isAuth = false;
      })
      .addCase(loadNewAccessToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadNewAccessToken.fulfilled, (state, action) => {
        const { accessToken } = action.payload;
        Cookies.set("accessToken", accessToken, { expires: 1 / 12, secure: true, sameSite: "strict" });
        state.isLoading = false;
        state.isAuth = true;
      })
      .addCase(loadAuthUser.rejected, (state) => {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        state.isAuth = false;
      })
      .addCase(loadAuthUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadAuthUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.isAuth = true;
      });
  },
});

export const { logout, setUserRegistr, clearUserRigistr, clearError, clearErrorMessage, clearErrorExistMessage } =
  authSlice.actions;
export default authSlice.reducer;
