// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ErrorWindow from "../components/modal_window/ErrorWindow";

const DEFAULT_COLOR = "#0059ff";
const DEFAULT_SIZE = 15;
const DEFAULT_INTENSIVE = 2;

const DEFAULT_BUTTON_OPTIONS = {
  count: 1,
  text1: "More info",
  text2: "Gift",
  colorTxt1: "#000000",
  colorTxt2: "#000000",
  colorBtn1: "#007bff",
  colorBtn2: "#28a745",
  fontSize: 14,
  url1: "",
  url2: "",
  displayCondition: 1,
};

const DEFAULT_SYNTHETIC = {
  name: "",
  left: "",
  right: "",
  bottom: "",
  avatarId: 0,
  languageId: 0,
  voiceId: 0,
  text: "",
};

const DefaultSettingsContext = createContext();

export const DefaultSettingsProvider = ({ children }) => {
  const [isErrorWindowOpen, setIsErrorWindowOpen] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const openErrorWindow = (text, redirect = false) => {
    setErrorText(text);
    setRedirectToLogin(redirect);
    setIsErrorWindowOpen(true);
  };

  const closeErrorWindow = () => {
    setIsErrorWindowOpen(false);
    setErrorText("");
    setRedirectToLogin(false);
  };

  const defaultSettings = useMemo(
    () => ({
      pulsarColor: DEFAULT_COLOR,
      pulsarSize: DEFAULT_SIZE,
      pulsarIntensive: DEFAULT_INTENSIVE,
      buttonOptions: DEFAULT_BUTTON_OPTIONS,
      syntheticDefaults: DEFAULT_SYNTHETIC,
      openErrorWindow,
      closeErrorWindow,
    }),
    []
  );

  return (
    <DefaultSettingsContext.Provider value={defaultSettings}>
      {children}
      <ErrorWindow
        open={isErrorWindowOpen}
        onClose={closeErrorWindow}
        text={errorText}
        redirectToLogin={redirectToLogin}
      />
    </DefaultSettingsContext.Provider>
  );
};

DefaultSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDefaultSettings = () => useContext(DefaultSettingsContext);
