import { memo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import styles from "./CustomSelect.module.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontFamily: "PTRootUIWebVF, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "0em",
    color: "#797979",
    minHeight: "52px",
    borderRadius: "8px",
    border: state.isFocused ? "0.5px solid #373d44" : "1px solid #98a2b3",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
    boxSizing: "content-box",
    width: "360px",
    height: "52px",
    backgroundColor: "#ffffff",
    boxShadow: state.isFocused ? "0 0 0 1.5px #373d44" : "none",
    "&:hover": {
      border: state.isFocused ? "0.5px solid #373d44" : "1px solid #98a2b3",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    gap: "8px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 5,
  }),
};

const CustomSelect = ({ label, name, options, value, onChange, error, isDisabled }) => (
  <div className={styles.container}>
    <label className={styles.label}>{label}</label>
    <Select
      styles={customStyles}
      classNamePrefix={styles.select}
      options={options}
      name={name}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
    />
    {error && <p className={styles.error}>{error}</p>}
  </div>
);

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
};

CustomSelect.defaultProps = {
  error: "",
  isDisabled: false,
};

export default memo(CustomSelect);
