import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Cookies from "../cookies/Cookies";
import Synthetic from "../../synthetic/Synthetic";
import styles from "./layout.module.css";

export default function Layout() {
  const onVideo = useSelector((state) => state.syntheticParams.onVideo);
  const videoClick = useSelector((state) => state.syntheticParams.videoClick);
  const [showScroll, setShowScroll] = useState(false);
  const [valueBottom, setValueBottom] = useState(0);
  const [isFixedToFooter, setIsFixedToFooter] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [checkScrollTop]);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById("footerBox");
      const footerOffsetTop = footer.offsetTop;
      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY;

      if (scrollTop + windowHeight >= footerOffsetTop) {
        setIsFixedToFooter(true);
        setValueBottom(scrollTop + windowHeight - footerOffsetTop);
      } else {
        setIsFixedToFooter(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Synthetic videoClick={videoClick} onVideo={onVideo} />
      <Navbar />
      <Outlet />
      <Footer />
      <Cookies />
      {showScroll && (
        <button
          type="button"
          className={styles.btn_up}
          style={{ bottom: isFixedToFooter ? `${valueBottom}px` : "3%" }}
          onClick={scrollToTop}
          aria-label="Up"
        >
          <img className={styles.imageIcon} src="/scroll_up.webp" alt="Scroll to Top" />
        </button>
      )}
    </>
  );
}
