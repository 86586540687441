import { axiosWithAuth } from "../auth/service/axios.config";

export async function subscribe(data) {
  const res = await axiosWithAuth.post("/api/subscription/new", JSON.stringify(data));
  if (res.status !== 201) {
    throw res.data;
  }
  return res.data;
}

export async function getUserActiveSubscriptionDetails() {
  const res = await axiosWithAuth.post("/api/subscription/current_user");
  if (res.status !== 200) {
    throw res.data;
  }
  return res.data;
}
