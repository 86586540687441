import styles from "./preLoading.module.css";

export default function PreLoading() {
  return (
    <div className={styles.preloader1}>
      <svg>
        <g>
          <path d="M 50,100 A 1,1 0 0 1 50,0" />
        </g>
        <g>
          <path d="M 50,75 A 1,1 0 0 0 50,-25" />
        </g>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop className={styles.stopElement} offset="0%" />
            <stop className={styles.stopElement} offset="100%" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
